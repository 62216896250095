import { createBrowserHistory } from 'history';
import { useEffect, useLayoutEffect, useState } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Router } from 'react-router-dom';
import { CONSTANT } from './constant/common';
import Routers from './routes';
import './styles/globals.scss';

const history = createBrowserHistory();
const CustomRouter = ({ history, ...props }: any) => {
	const [state, setState] = useState({
		action: history.action,
		location: history.location,
	});
	useLayoutEffect(() => history.listen(setState), [history]);

	return <Router {...props} location={state.location} navigationType={state.action} navigator={history} />;
};
function App() {
	// eslint-disable-next-line no-console
	const warn = console.warn;
	function logWarning(...warnings: any[]) {
		let showWarning = true;
		warnings.forEach((warning) => {
			if (warning.includes('UNSAFE_')) showWarning = false;
			else if (warning.includes('SourceMap')) showWarning = false;
			else if (warning.includes('DevTools')) showWarning = false;
		});
		if (showWarning) warn(...warnings);
	}
	// eslint-disable-next-line no-console
	console.warn = logWarning;
	useEffect(() => {
		if (window.location.pathname === '/') {
			window.location.href = '/admin/login';
		}
	}, []);
	return (
		<CustomRouter history={history}>
			<GoogleReCaptchaProvider
				reCaptchaKey={CONSTANT.RECAPTCHA_KEY}
				scriptProps={{
					async: false,
					defer: false,
					appendTo: 'head',
					nonce: undefined,
				}}
			>
				<div className='App'>
					<Routers />
				</div>
			</GoogleReCaptchaProvider>
		</CustomRouter>
	);
}
export default App;
