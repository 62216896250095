import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ButtonWhite from '../../../components/Button/buttonWhite';
import CardFeature from '../../../components/Card/CardFeature';
import FilterComponent from '../../../components/Filter';
import MenuButtonComponent from '../../../components/Menu/menuButton';
import TableComponents from '../../../components/Table';
import TextFieldMuiComp from '../../../components/Textfield/textfieldMui';
import { ScoreDiaryPaging } from '../../../constant/column/common';
import { ESurveyCandidateStatus, Role } from '../../../constant/common';
import { ScoreDiaryApiCall } from '../../../services/Admin/ScoreDiary';
import styles from './ScoreDiary.module.scss';

const filterStatus = [
	{
		label: 'DO NOT JOIN SURVEY CAMPAIGN',
		value: ESurveyCandidateStatus.NOT_JOIN_SURVEY_CAMPAIGN,
	},
	{
		label: 'HAVENOT CREATED ACCOUNT YET',
		value: ESurveyCandidateStatus.HAVENOT_CREATE_ACCOUNT_YET,
	},
	{
		label: 'POINTS EARNED',
		value: ESurveyCandidateStatus.POINTS_EARNED,
	},
];

function ScoreDiaryManagerProps() {
	const ref = useRef(null);
	const navigate = useNavigate();
	//user-paging
	const [getAllScore, setGetAllScore] = useState<any>();
	//trigger
	const [trigger, setTrigger] = useState(-1);
	const [openBoxFilter, setOpenBoxFilter] = useState(null);
	const [opened, setOpened] = useState(false);
	//pagination
	// const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [userInfor, setUserInfor] = useState<any>();
	const [filterStats, setFilterStatus] = useState<any>();
	const [params] = useSearchParams();
	const page: any = params.get('page');

	const listScoreDiaryQuery = useQuery(
		['scoreDiary', { page, rowsPerPage, filterStats }],
		async () => {
			const res = await ScoreDiaryApiCall.getScoreDiaryPaging({
				page: page ? page : 1,
				limit: rowsPerPage,
				status: filterStats,
			});
			return res.data;
		},
		{
			refetchOnWindowFocus: false,
			onSuccess: (data) => {
				setGetAllScore(data.data);
			},
			onError: () => {
				toast.error('Get List Score Diary Paging Fail!');
			},
		}
	);
	const handleChangePage = (_: any, newPage: number) => {
		navigate(`?page=${newPage + 1}`, { replace: true });
	};
	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setRowsPerPage(+event.target.value);
		navigate(`?page=${1}`, { replace: true });
	};
	const handleClick = (event: any) => {
		if (opened) {
			setOpenBoxFilter(null);
			return;
		} else {
			setOpenBoxFilter(event.currentTarget);
		}
	};
	const handleFilterStatus = (data: any) => {
		setFilterStatus(data.target.value);
	};
	useEffect(() => {
		if (openBoxFilter !== null) {
			setOpened(true);
		}
		if (openBoxFilter === null) {
			setOpened(false);
		}
	}, [openBoxFilter]);
	useEffect(() => {
		const getUser = localStorage.getItem('userInfor');
		if (getUser) {
			setUserInfor(JSON.parse(getUser));
		}
	}, []);
	useEffect(() => {
		if (page === null) {
			navigate(`?page=${1}`, { replace: true });
		}
	}, [page]);
	useEffect(() => {
		document.title = userInfor?.role !== Role.AMIN_OF_SALE ? 'Admin - Score Diary' : 'Admin Of Sale - Score Diary';
	}, [userInfor]);
	const renderStatus = (value: any) => {
		let result = '';
		switch (Number(value)) {
			case ESurveyCandidateStatus.NOT_JOIN_SURVEY_CAMPAIGN:
				result = 'DO NOT JOIN SURVEY CAMPAIGN';
				break;
			case ESurveyCandidateStatus.HAVENOT_CREATE_ACCOUNT_YET:
				result = 'HAVENOT CREATED ACCOUNT YET';
				break;
			case ESurveyCandidateStatus.POINTS_EARNED:
				result = 'POINTS EARNED';
				break;
			default:
				break;
		}
		return result;
	};
	return (
		<div className={styles.user}>
			<div className={styles.user__toolbar}>
				<div className={styles.user__toolbar__container}>
					<div className={styles.user__toolbar__container__title}>
						<h1 className={styles.user__toolbar__container__title__header}>Score Diary</h1>
					</div>
					<div className={styles.user__toolbar__container__group}>
						<div style={{ margin: 0 }}>
							<MenuButtonComponent
								title={'Filter'}
								button={
									<ButtonWhite
										title={'Filter'}
										type={'button'}
										style={{
											height: '40px',
											width: '90px',
										}}
										icon={<FilterAltRoundedIcon fontSize='small' />}
										onClick={handleClick}
									/>
								}
								menuItem={
									<div>
										<div className={styles.user__toolbar__container__group__box__title}>Filter Options</div>

										<div className={styles.separator}></div>

										<div className={styles.user__toolbar__container__group__box__menu}>
											<label className={styles.user__toolbar__container__group__box__menu__brand}>Status:</label>
											<FilterComponent
												name={'status'}
												onChange={(e: any) => handleFilterStatus(e)}
												value={filterStats}
												listSub={''}
												menuData={filterStatus}
												formProps={{
													label: 'status',
													className: styles.user__toolbar__container__group__box__menu__select,
												}}
											/>
										</div>
									</div>
								}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className={styles.user__content}>
				<div className={styles.user__content__container}>
					<CardFeature
						title={'Score Diary'}
						content={
							<TableComponents
								title=''
								icon={undefined}
								tableHead={ScoreDiaryPaging.map((item, index) => (
									<TableCell
										key={index}
										align={item.align}
										style={{
											minWidth: item.minWidth,
										}}
										className={styles.user__content__container__head}
										ref={ref}
									>
										<div className={styles.user__content__container__head__box}>
											{item.label}
											<MenuButtonComponent
												title={'Search'}
												button={
													<div
														className={styles.user__icon}
														onClick={() => {
															if (trigger === index) {
																setTrigger(-1);
																return;
															}

															setTrigger(index);
														}}
													>
														{item.icon}
													</div>
												}
												menuItem={
													<div className={styles.user__search}>
														<div className={styles.user__toolbar__container__group__box__menu}>
															<TextFieldMuiComp
																name={'search-email'}
																onChange={(e: any) => {
																	ScoreDiaryApiCall.getScoreDiaryPaging({
																		page,
																		limit: rowsPerPage,
																		email: e.target.value,
																	})
																		.then((response) => {
																			setGetAllScore(response.data);
																		})
																		.catch(() => {
																			toast.error('Search Email Paging Fail!');
																		});
																}}
																type='text'
																placeholder='Search'
															/>
														</div>
													</div>
												}
											/>
										</div>
									</TableCell>
								))}
								tableData={listScoreDiaryQuery.data?.data.map((item: any, index: number) => {
									return (
										<TableRow hover role='checkbox' tabIndex={-1} key={index}>
											<TableCell align={'left'} className={styles.user__content__container__cellSmall}>
												{index + 1}
											</TableCell>
											<TableCell align={'left'} className={styles.user__content__container__cellMedium}>
												{moment(item.firstJoinedAt).format('DD/MM/YYYY')}
											</TableCell>
											<TableCell align={'left'} className={styles.user__content__container__cellMedium}>
												{item.email}
											</TableCell>
											<TableCell align={'left'} className={styles.user__content__container__cellMedium}>
												{item.earnedPoints}
											</TableCell>
											<TableCell align={'left'} className={styles.user__content__container__cellMedium}>
												{moment(item.earnedDate).format('DD/MM/YYYY')}
											</TableCell>
											<TableCell align={'left'} className={styles.user__content__container__cellMedium}>
												{moment(item.registerAt).format('DD/MM/YYYY')}
											</TableCell>
											<TableCell align={'left'} className={styles.user__content__container__cellMedium}>
												{moment(item.surveyDate).format('DD/MM/YYYY')}
											</TableCell>
											<TableCell align={'left'} className={styles.user__content__container__cellMedium}>
												{renderStatus(item.status)}
											</TableCell>
										</TableRow>
									);
								})}
								dataTable={listScoreDiaryQuery.data?.meta.totalItems}
								page={Number(page - 1)}
								rowsPerPage={rowsPerPage}
								onPageChange={handleChangePage}
								onRowsPerPageChange={handleChangeRowsPerPage}
							/>
						}
						icon={undefined}
						background={undefined}
					/>
				</div>
			</div>
		</div>
	);
}
export default ScoreDiaryManagerProps;
