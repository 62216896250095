/* eslint-disable @typescript-eslint/no-unused-vars */
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import WorkIcon from '@mui/icons-material/Work';
import { useLocation } from 'react-router-dom';
import styles from './SideBar.module.scss';

const ListMenu: {
	id: number;
	name: string;
	icon: any;
	path: string;
	route: string;
}[] = [
	{
		id: 1,
		name: 'Upload CV',
		icon: <LibraryAddIcon fontSize='small' />,
		path: '/input/upload-cv',
		route: '/input/upload-cv',
	},
	{
		id: 2,
		name: 'Input CV',
		icon: <WorkIcon fontSize='small' />,
		path: '/input/cv',
		route: '/input/cv',
	},
];
export function InputSideBar() {
	const location = useLocation();
	const drawer = (
		<div className={styles.drawer}>
			<div className={styles.drawer__logo}>
				<a href='/input/home'>Input</a>
			</div>
			<div className={styles.drawer__list}>
				<div className={styles.drawer__list__wrapper}>
					<div className={styles.drawer__list__wrapper__menu}>
						<div className={styles.drawer__list__wrapper__menu__item}>
							{ListMenu.map((item, index) => (
								<a
									key={index}
									className={
										location.pathname === item.route
											? styles.drawer__list__wrapper__menu__item__linkActive
											: styles.drawer__list__wrapper__menu__item__link
									}
									href={item.path}
								>
									<span
										className={
											location.pathname === item.route
												? styles.drawer__list__wrapper__menu__item__linkActive__icon
												: styles.drawer__list__wrapper__menu__item__link__icon
										}
									>
										{item.icon}
									</span>
									<span
										className={
											location.pathname === item.route
												? styles.drawer__list__wrapper__menu__item__linkActive__title
												: styles.drawer__list__wrapper__menu__item__link__title
										}
									>
										{item.name}
									</span>
								</a>
							))}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
	return (
		<>
			<div className={styles.sidebar}>{drawer}</div>
		</>
	);
}
