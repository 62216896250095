/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { Badge } from '@mui/material';
import Cookies from 'js-cookie';
import { useEffect, useRef, useState } from 'react';
import imgUser from '../../asset/img/img_avatar.png';
import MenuAvatarComponent from '../../components/Menu';
import { RoutesRedirect } from '../../constant/routes';
import styles from './Header.module.scss';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { QAndAFromCandidateAndHrConfigApi } from '../../services/Admin/qAndAFromCandidateAndHr';
import { ENotificationJobComment } from '../../constant/common';
import { toast } from 'react-toastify';
function Header() {
	const navigate = useNavigate();
	const BASE_PATH = process.env.REACT_APP_IMAGE_URL;
	const ref = useRef(null);
	const [userInfor, setUserInfor] = useState<any>();
	useEffect(() => {
		const getUser = localStorage.getItem('userInfor');
		if (getUser) {
			setUserInfor(JSON.parse(getUser));
		}
	}, []);

	const handleLogOut = () => {
		Cookies.remove('role');
		Cookies.remove('access_token');
		localStorage.removeItem('userInfor');
		window.location.pathname = RoutesRedirect.loginPage;
	};

	const handleGoToDetailComment = async (item: any) => {
		if (item?.type === ENotificationJobComment.CREATED_COMMENT) {
			navigate(
				`/admin/comment-questions-and-answers-job/${item?.metadata?.data?.job?.uuid}?questionId=${item?.metadata?.data?.uuid}`
			);
		} else if (item?.type === ENotificationJobComment.CREATED_REPLY_COMMENT) {
			navigate(
				`/admin/comment-questions-and-answers-job/reply/${item?.metadata?.data?.mainComment?.uuid}?answerId=${item?.metadata?.data?.uuid}`
			);
		}

		try {
			await QAndAFromCandidateAndHrConfigApi.v1PutClickNotification(item?.uuid);
			jobCommentNotificationQuery?.refetch();
		} catch (error: any) {
			toast.error(error?.response?.data?.message);
		}
	};

	const jobCommentNotificationQuery = useQuery(
		['jobCommentNotificationQuery'],
		async () => {
			if (
				userInfor?.role === 'U_ADMIN' ||
				userInfor?.role === 'U_ADMIN_OF_SALE' ||
				userInfor?.role === 'U_SALE_ADMIN'
			) {
				const res = await QAndAFromCandidateAndHrConfigApi.v1GetNotification();
				return res.data;
			} else {
				return null;
			}
		},
		{
			refetchInterval: 90000,
		}
	);

	return (
		<div className={styles.navbar}>
			<div className={styles.navbar__container}>
				<div className={styles.navbar__logo}></div>
				<div className={styles.navbar__right}>
					{(userInfor?.role === 'U_ADMIN' ||
						userInfor?.role === 'U_ADMIN_OF_SALE' ||
						userInfor?.role === 'U_SALE_ADMIN') && (
						<MenuAvatarComponent
							title={
								<>
									{jobCommentNotificationQuery?.data?.data?.filter((item: any) => !item?.isClick)?.length > 0 && (
										<>
											You need to approve{' '}
											{jobCommentNotificationQuery?.data?.data?.filter((item: any) => !item?.isClick)?.length} comments{' '}
										</>
									)}
								</>
							}
							avatar={
								<Badge
									badgeContent={jobCommentNotificationQuery?.data?.data?.filter((item: any) => !item?.isClick)?.length}
									color='primary'
								>
									<NotificationsNoneIcon />
								</Badge>
							}
							menuItem={
								<div className={'w-[400px] p-[10px] overflow-auto max-h-[300px]'}>
									{jobCommentNotificationQuery?.data?.data?.map((item: any, index: number) => (
										<div
											className={`hover:bg-slate-50 p-[10px] rounded-[8px] cursor-pointer ${
												!item?.isClick && 'bg-slate-50'
											} mb-[10px]`}
											key={`notification-${index}`}
											onClick={() => handleGoToDetailComment(item)}
										>
											<div className='flex justify-between'>
												<div className='flex'>
													<img
														src={`${BASE_PATH}${item?.metadata?.data?.user?.avatar || ''}`}
														alt='avatar'
														className='object-cover rounded w-[40px] h-[40px]'
													/>
													<div className='flex items-center'>
														<div className='ml-[16px]'>
															<div className='flex items-center mb-1'>
																<h3 className='text-[#787878] text-[14px] m-0'>
																	{item?.metadata?.data?.user?.full_name}
																</h3>
																{item?.metadata?.status}
															</div>
															<p className='text-[16px] mb-0 mt-[10px] truncate w-[320px]'>
																{item?.metadata?.data?.comment}
															</p>
														</div>
														<div
															className={`${!item?.isClick && 'w-[10px] h-[10px] rounded-[50%] bg-[green] mr-[6px]'}`}
														/>
													</div>
												</div>
											</div>
										</div>
									))}
								</div>
							}
						/>
					)}
					<MenuAvatarComponent
						avatar={
							<div>
								<img src={imgUser} alt='img-avt' className={styles.navbar__user__avt__img} />
							</div>
						}
						title={<>{userInfor?.email}</>}
						menuItem={
							<div>
								<div className={styles.navbar__user__menu__itemHeader}>
									<div className={styles.navbar__user__menu__itemHeader__content}>
										<div className={styles.navbar__user__menu__itemHeader__content__avt}>
											<img src={imgUser} alt='img-avt' />
										</div>
										<div className={styles.navbar__user__menu__itemHeader__content__title}>
											<div className={styles.navbar__user__menu__itemHeader__content__title__name}>
												{userInfor?.last_name} {userInfor?.first_name}
											</div>
											<a href='#'>{userInfor?.email}</a>
										</div>
									</div>
								</div>

								<div className={styles.separator}></div>

								<div className={styles.navbar__user__menu__item}>
									<div onClick={handleLogOut} ref={ref} className={styles.navbar__user__menu__item__link}>
										Sign Out
									</div>
								</div>
							</div>
						}
					/>
				</div>
			</div>
		</div>
	);
}
export default Header;
