import { stringify } from 'querystring';
import { instance } from './axiosClient';
import { IResponseList } from '../../models/common';

export class educationTermApiCall {
	public static getListScoreDiaryPaging(currentPage?: number, numberRow?: number) {
		const pageParam = currentPage ? currentPage + 1 : 1;
		const pageRows = numberRow ? numberRow : 10;

		return instance.get(`/school/paging?lang=en&page=${pageParam}&limit=${pageRows}`);
	}
	public static getScoreDiaryPaging(
		currentPage?: number,
		searchField?: string,
		filterField?: string,
		pathField?: string
	) {
		const pageParam = currentPage ? currentPage : 0;
		const filterParam = filterField
			? `&filter.schoolname=$ilike${filterField}`
			: '';
		const pathParam = pathField ? `&filter=${pathField}` : '';

		return instance.get(
			`/school?lang=en&page=${pageParam}&limit=1000${filterParam}${pathParam}`
		);
	}
	static async v1GetPagingApi(
		page: number,
		limit: number,
		filter?:string,
	): Promise<IResponseList<any>> {
		const filterParam = filter
			? `&filter.schoolname=%24ilike%3A${filter}`
			: '';
			const pageParam = page ? page : 0;
			const limitParam = limit ? limit : 0;

		const res = await instance.get(
			`/school/paging?page=${pageParam}&limit=${limitParam}${filterParam}`
		);

		return res.data;
	}

	static async filterSchoolName(name: string): Promise<any> {
		const res = await instance.get(`/school/paging?filter.schoolname=%24ilike%3A${name}`);
		return res.data;
	}
	static async accept(id: string): Promise<any> {
		const res = await instance.put(`/school/${id}`, {
			isVerify: true
		});

		return res.data;
	}

	static async reject(id: string): Promise<any> {
		const res = await instance.put(`/school/${id}`, {
			isVerify: false
		});

		return res.data;
	}
}
