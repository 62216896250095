/* eslint-disable @typescript-eslint/no-unused-vars */
import { withStyles } from '@material-ui/core';
import MuiListItem from '@material-ui/core/ListItem';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Collapse, List, ListItemIcon, ListItemText } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Role } from '../../constant/common';
import { ListMenu } from '../../constant/sidebar';
import { headerActions, selectorSetOpenList } from '../../redux/Slices/Header';
import styles from './SideBar.module.scss';

interface InitialStateI {
	[key: string]: boolean;
}

const ListItem = withStyles({
	root: {
		'& .MuiListItemIcon-root': {
			color: '#9d9da6',
		},
		'&$selected': {
			backgroundColor: 'rgba(25, 118, 210, 0.08)',
			color: 'white',
			'& .MuiListItemIcon-root': {
				color: 'white',
			},
		},
		'&$selected:hover': {
			backgroundColor: 'rgba(25, 118, 210, 0.08)',
			color: 'white',
			'& .MuiListItemIcon-root': {
				color: 'white',
			},
		},
		'&:hover': {
			backgroundColor: 'rgba(25, 118, 210, 0.08)',
			color: 'white',
			'& .MuiListItemIcon-root': {
				color: 'white',
			},
		},
	},
	selected: {},
})(MuiListItem);

function SideBar() {
	const navigate = useNavigate();
	const [userInfor, setUserInfor] = useState<any>();
	const initialState: InitialStateI = Object.fromEntries(ListMenu.map((i: any) => [i.name, false]));
	useEffect(() => {
		const getUser = localStorage.getItem('userInfor');
		if (getUser) {
			setUserInfor(JSON.parse(getUser));
		}
	}, []);
	const SingleLevel = ({ item }: any) => {
		return (
			<ListItem
				button
				key={item.id}
				onClick={() => {
					if (item?.path) {
						navigate(item.path);
					}
				}}
				selected={window.location.pathname == item?.path}
				style={{ color: window.location.pathname == item?.path ? '#FFF' : '#9d9da6' }}
			>
				{item.icon && (
					<ListItemIcon
						style={{
							color: window.location.pathname == item?.path ? '#FFF' : '#9d9da6',
						}}
					>
						{item.icon}
					</ListItemIcon>
				)}
				<ListItemText primary={item.name} />
			</ListItem>
		);
	};
	const MultiLevel = ({ item }: any) => {
		const { items: children } = item;
		const dispatch = useDispatch();
		const openListItem = useSelector(selectorSetOpenList);
		const handleClick = (itemName: string) => {
			dispatch(
				headerActions.setOpenList((o: any) => {
					return { ...initialState, [itemName]: !o[itemName] };
				})
			);
		};
		return (
			<List component='nav' aria-labelledby='nested-list-subheader' key={item?.id}>
				<ListItem
					button
					onClick={() => handleClick(item.name)}
					selected={openListItem[item.name]}
					style={{ color: openListItem[item.name] ? '#FFF' : '#9d9da6' }}
				>
					{item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
					<ListItemText primary={item.name} />
					{openListItem[item.name] ? <ExpandLess /> : <ExpandMore />}
				</ListItem>
				<Collapse in={openListItem[item.name]} timeout='auto' unmountOnExit>
					<List component='div' disablePadding>
						{children.map((child: any, key: any) => (
							<ListItem
								button
								onClick={() => {
									navigate(child.path);
								}}
								selected={window.location.pathname == child?.path}
								style={{
									color: window.location.pathname == child?.path ? '#FFF' : '#9d9da6',
									marginLeft: '10px',
								}}
								key={key}
							>
								<ListItemIcon
									style={{
										color: window.location.pathname == child?.path ? '#FFF' : '#9d9da6',
									}}
								>
									{child.icon}
								</ListItemIcon>
								<ListItemText primary={child.name} />
							</ListItem>
						))}
					</List>
				</Collapse>
			</List>
		);
	};

	const drawer = (
		<div className={styles.drawer}>
			<div className={styles.drawer__logo}>
				{userInfor?.role !== Role.AMIN_OF_SALE ? (
					<a href='/admin/home'>Admin</a>
				) : (
					<a href='/admin/user'>Admin Of Sale</a>
				)}
			</div>
			<div className={styles.drawer__list}>
				<div className={styles.drawer__list__wrapper}>
					<div className={styles.drawer__list__wrapper__menu}>
						{ListMenu.map(
							(item: any) =>
								item?.role.includes(userInfor?.role) && (
									<div className={styles.drawer__list__wrapper__menu__item} key={item.id}>
										{item?.items ? <MultiLevel item={item} /> : <SingleLevel item={item} />}
									</div>
								)
						)}
					</div>
				</div>
			</div>
		</div>
	);

	return (
		<>
			<div className={styles.sidebar}>{drawer}</div>
		</>
	);
}
export default SideBar;
