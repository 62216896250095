import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
export const UserPaging: {
	id: '#' | 'userId' | 'full_name' | 'email' | 'phone' | 'country' | 'createdAt' | 'role' | 'status';
	label: string;
	minWidth?: number;
	align?: 'left' | 'right' | 'center';
	format?: (value: number) => string;
	icon: any;
	checkIcon: boolean;
}[] = [
	{ id: '#', label: '#', minWidth: 50, icon: '', checkIcon: false },
	{
		id: 'full_name',
		label: 'Full Name',
		minWidth: 200,
		align: 'left',
		icon: <SearchRoundedIcon fontSize='small' sx={{ fontWeight: 300, cursor: 'pointer' }} />,
		checkIcon: true,
	},
	{
		id: 'email',
		label: 'Email',
		minWidth: 170,
		align: 'left',
		icon: <SearchRoundedIcon fontSize='small' sx={{ fontWeight: 300, cursor: 'pointer' }} />,
		checkIcon: false,
		
	},

	{
		id: 'phone',
		label: 'Phone',
		minWidth: 100,
		align: 'center',
		icon: '',
		checkIcon: false,
	},
	{
		id: 'country',
		label: 'Country',
		minWidth: 80,
		align: 'center',
		icon: '',
		checkIcon: false,
	},
	{
		id: 'role',
		label: 'Role',
		minWidth: 100,
		align: 'center',
		icon: '',
		checkIcon: false,
	},
	{
		id: 'status',
		label: 'Status',
		minWidth: 50,
		align: 'center',
		icon: '',
		checkIcon: false,
	},
	{
		id: 'createdAt',
		label: 'Created At',
		minWidth: 100,
		align: 'center',
		icon: '',
		checkIcon: false,
	},
];

export const LogSystems: {
	id: '#' | 'level' | 'context' | 'message' | 'service' | 'timestamp';
	label: string;
	minWidth?: number;
	align?: 'left' | 'right';
}[] = [
	{
		id: '#',
		label: '#',
		minWidth: 50,
	},
	{
		id: 'level',
		label: 'Level',
		minWidth: 50,
	},
	{
		id: 'context',
		label: 'Context',
		minWidth: 100,
	},
	{
		id: 'message',
		label: 'Message',
		minWidth: 200,
	},
	{
		id: 'service',
		label: 'Service',
		minWidth: 100,
	},
	{
		id: 'timestamp',
		label: 'TimeStamp',
		minWidth: 100,
	},
];

export const LogBuyCV: {
	id: '#' | 'id' | 'message' | 'buyer' | 'company' | 'updateAt';
	label: string;
	minWidth?: number;
	align?: 'left' | 'right';
}[] = [
	{
		id: '#',
		label: '#',
		minWidth: 50,
	},
	{
		id: 'id',
		label: 'Id',
		minWidth: 50,
	},
	{
		id: 'buyer',
		label: 'Buyer',
		minWidth: 170,
	},
	{
		id: 'company',
		label: 'Company',
		minWidth: 170,
	},
	{
		id: 'message',
		label: 'Message',
		minWidth: 170,
	},
	{
		id: 'updateAt',
		label: 'UpdateAt',
		minWidth: 170,
	},
];

export const LogJobseeker: {
	id: '#' | 'level' | 'message' | 'service' | 'stack' | 'timestamp';
	label: string;
	minWidth?: number;
	align?: 'left' | 'right';
}[] = [
	{
		id: '#',
		label: '#',
		minWidth: 50,
	},
	{
		id: 'level',
		label: 'Level',
		minWidth: 50,
	},

	{
		id: 'message',
		label: 'Message',
		minWidth: 200,
	},
	{
		id: 'service',
		label: 'Service',
		minWidth: 170,
	},
	{
		id: 'stack',
		label: 'Stack',
		minWidth: 170,
	},
	{
		id: 'timestamp',
		label: 'TimeStamp',
		minWidth: 100,
	},
];

export const UserEmployerHead: {
	id: '#' | 'userId' | 'full_name' | 'email' | 'phone' | 'company' | 'country' | 'role' | 'createdAt';
	label: string;
	minWidth?: number;
	align?: 'left' | 'right';
	format?: (value: number) => string;
	icon: any;
}[] = [
	{ id: '#', label: '#', minWidth: 50, icon: '' },
	{
		id: 'full_name',
		label: 'Full Name',
		minWidth: 170,
		align: 'left',
		icon: <SearchRoundedIcon fontSize='small' sx={{ fontWeight: 300, cursor: 'pointer' }} />,
	},
	{
		id: 'email',
		label: 'Email',
		minWidth: 170,
		align: 'left',
		icon: '',
	},
	{
		id: 'phone',
		label: 'Phone',
		minWidth: 100,
		align: 'left',
		icon: '',
	},
	// {
	// 	id: 'company',
	// 	label: 'Company',
	// 	minWidth: 100,
	// 	align: 'left',
	// 	icon: '',
	// },
	{
		id: 'country',
		label: 'Country',
		minWidth: 100,
		align: 'left',
		icon: '',
	},
	{
		id: 'role',
		label: 'Role',
		minWidth: 100,
		align: 'left',
		icon: '',
	},
	{
		id: 'createdAt',
		label: 'Created At',
		minWidth: 100,
		align: 'left',
		icon: '',
	},
];

export const UserJobseekersHead: {
	id: 'no.' | 'userId' | 'full_name' | 'email' | 'phone' | 'country' | 'role' | 'createdAt';
	label: string;
	minWidth?: number;
	align?: 'left' | 'right' | 'center';
	format?: (value: number) => string;
	icon: any;
}[] = [
	{ id: 'no.', label: 'No.', minWidth: 50, icon: '' },
	{
		id: 'full_name',
		label: 'Full Name',
		minWidth: 170,
		align: 'left',
		icon: <SearchRoundedIcon fontSize='small' sx={{ fontWeight: 300, cursor: 'pointer' }} />,
	},
	{
		id: 'email',
		label: 'Email',
		minWidth: 170,
		align: 'left',
		icon: '',
	},

	{
		id: 'phone',
		label: 'Phone',
		minWidth: 100,
		align: 'left',
		icon: '',
	},
	{
		id: 'country',
		label: 'Country',
		minWidth: 100,
		align: 'left',
		icon: '',
	},
	{
		id: 'role',
		label: 'Role',
		minWidth: 50,
		align: 'left',
		icon: '',
	},
	{
		id: 'createdAt',
		label: 'Created At',
		minWidth: 100,
		align: 'left',
		icon: '',
	},
];

export const UserStaff: {
	id: 'no.' | 'userId' | 'email' | 'role' | 'createdAt' | 'status';
	label: string;
	minWidth?: number;
	align?: 'left' | 'right' | 'center';
	icon?: any;
}[] = [
	{ id: 'no.', label: 'No.', minWidth: 50 },
	{ id: 'email', label: 'Email', minWidth: 200, icon: <SearchRoundedIcon fontSize='small' sx={{ fontWeight: 300, cursor: 'pointer' }} />},
	{
		id: 'role',
		label: 'Role',
		minWidth: 50,
		align: 'left',
	},
	{
		id: 'createdAt',
		label: 'Created At',
		minWidth: 50,
		align: 'left',
	},
	{
		id: 'status',
		label: 'Status',
		minWidth: 50,
		align: 'center',
	},
];

export const ListNews: {
	id: 'no.' | 'posterName' | 'PosterEmail' | 'title' | 'request' | 'level' | 'status';

	label: string;
	minWidth?: number;
	align?: 'left' | 'right';
	format?: (value: number) => string;
}[] = [
	{ id: 'no.', label: 'No.', minWidth: 50 },
	{
		id: 'title',
		label: 'Title',
		minWidth: 200,
		align: 'left',
	},
	{
		id: 'posterName',
		label: 'Poster Name',
		minWidth: 170,
		align: 'left',
	},
	{
		id: 'PosterEmail',
		label: 'Poster Email',
		minWidth: 170,
		align: 'left',
	},

	{
		id: 'request',
		label: 'Request',
		minWidth: 50,
		align: 'left',
	},
	{
		id: 'level',
		label: 'Level',
		minWidth: 50,
		align: 'left',
	},
	{ id: 'status', label: 'Status', minWidth: 50 },
];

export const ListCompany: {
	id: 'no.' | 'id' | 'name' | 'nameEn' | 'email_company' | 'email_user' | 'address' | 'updatedAt' | 'createdAt';
	label: string;
	minWidth?: number;
	align?: 'left' | 'right';
	format?: (value: number) => string;
	icon?: any;
}[] = [
	{ id: 'no.', label: 'No.', minWidth: 50 },
	{
		id: 'name',
		label: 'Name',
		minWidth: 170,
		align: 'left',
	},
	{
		id: 'nameEn',
		label: 'Name En',
		minWidth: 170,
		align: 'left',
	},
	{
		id: 'email_company',
		label: 'Email Company',
		minWidth: 170,
		align: 'left',
	},
	{
		id: 'email_user',
		label: 'Email User',
		minWidth: 170,
		align: 'left',
		icon: <SearchRoundedIcon fontSize='small' sx={{ fontWeight: 300, cursor: 'pointer' }} />
	},
	{
		id: 'address',
		label: 'Address',
		minWidth: 170,
		align: 'left',
	},
	{
		id: 'updatedAt',
		label: 'Updated At',
		minWidth: 100,
		align: 'left',
	},
	{
		id: 'createdAt',
		label: 'Created At',
		minWidth: 100,
		align: 'left',
	},
];

export const ListTransaction: {
	id: '#' | 'id' | 'trader' | 'amount' | 'createdAt' | 'email';
	label: string;
	minWidth?: number;
	align?: 'left' | 'right';
	format?: (value: number) => string;
}[] = [
	{ id: '#', label: '#', minWidth: 50 },
	{
		id: 'trader',
		label: 'Trader',
		minWidth: 200,
		align: 'left',
	},
	{
		id: 'email',
		label: 'Email',
		minWidth: 200,
		align: 'left',
	},
	{
		id: 'amount',
		label: 'Amount',
		minWidth: 50,
		align: 'left',
	},
	{
		id: 'createdAt',
		label: 'Created At',
		minWidth: 50,
		align: 'left',
	},
];

export const ListPackage: {
	id:
		| '#'
		| 'id'
		| 'name'
		| 'highlight_vi'
		| 'highlight_en'
		| 'description_vi'
		| 'description_en'
		| 'value'
		| 'price'
		| 'status'
		| 'edit'
		| 'delete';

	label: string;
	minWidth?: number;
	align?: 'left' | 'right' | 'center';
	format?: (value: number) => string;
}[] = [
	{ id: '#', label: '#', minWidth: 50 },
	{
		id: 'name',
		label: 'Name',
		minWidth: 50,
		align: 'left',
	},
	{
		id: 'highlight_vi',
		label: 'Highlight_VI',
		minWidth: 200,
		align: 'left',
	},
	{
		id: 'highlight_en',
		label: 'Highlight_EN',
		minWidth: 200,
		align: 'left',
	},
	{
		id: 'description_vi',
		label: 'Description_VI',
		minWidth: 200,
		align: 'left',
	},
	{
		id: 'description_en',
		label: 'Description_VI',
		minWidth: 200,
		align: 'left',
	},
	{
		id: 'value',
		label: 'Value',
		minWidth: 50,
		align: 'left',
	},
	{
		id: 'price',
		label: 'Price',
		minWidth: 50,
		align: 'left',
	},
	{
		id: 'status',
		label: 'Status',
		minWidth: 50,
		align: 'left',
	},
	{
		id: 'edit',
		label: 'Edit',
		minWidth: 50,
		align: 'center',
	},
	{
		id: 'delete',
		label: 'Delete',
		minWidth: 50,
		align: 'center',
	},
];
export const ListReleaseManagement: {
	id:
		| '#'
		| 'id'
		| 'site'
		| 'title_en'
		| 'title_vi'
		| 'created_at'
		| 'description_vi'
		| 'description_en'
		| 'edit'
		| 'delete';

	label: string;
	minWidth?: number;
	align?: 'left' | 'right' | 'center';
	format?: (value: number) => string;
}[] = [
	{ id: '#', label: '#', minWidth: 50 },
	{
		id: 'site',
		label: 'Site',
		minWidth: 50,
		align: 'left',
	},
	{
		id: 'title_en',
		label: 'Title EN',
		minWidth: 200,
		align: 'left',
	},
	{
		id: 'title_vi',
		label: 'Title VN',
		minWidth: 200,
		align: 'left',
	},
	{
		id: 'created_at',
		label: 'Created at notification',
		minWidth: 200,
		align: 'left',
	},
	{
		id: 'description_vi',
		label: 'Description_VI',
		minWidth: 200,
		align: 'left',
	},
	{
		id: 'description_en',
		label: 'Description_EN',
		minWidth: 200,
		align: 'left',
	},
	{
		id: 'edit',
		label: 'Edit',
		minWidth: 50,
		align: 'center',
	},
	{
		id: 'delete',
		label: 'Delete',
		minWidth: 50,
		align: 'center',
	},
];
export const ListEmployerRefererManagement: {
	id: '#' | 'company_name' | 'email' | 'amount_referer_done' | 'amount_referer_pending' | 'detail' | 'edit';

	label: string;
	minWidth?: number;
	align?: 'left' | 'right' | 'center';
	format?: (value: number) => string;
}[] = [
	{ id: '#', label: '#', minWidth: 50 },
	{
		id: 'company_name',
		label: 'Company name',
		minWidth: 50,
		align: 'left',
	},
	{
		id: 'email',
		label: 'Email',
		minWidth: 200,
		align: 'left',
	},
	{
		id: 'amount_referer_done',
		label: 'Number of successful referrals',
		minWidth: 200,
		align: 'left',
	},
	{
		id: 'amount_referer_pending',
		label: 'Number of times waiting to be completed',
		minWidth: 200,
		align: 'left',
	},
	{
		id: 'detail',
		label: 'Detail',
		minWidth: 50,
		align: 'center',
	},
	{
		id: 'edit',
		label: 'Edit',
		minWidth: 50,
		align: 'center',
	},
];
export const ListOptimizeCV: {
	id: '#' | 'email' | 'limit' | 'current' | 'edit';

	label: string;
	minWidth?: number;
	align?: 'left' | 'right' | 'center';
	format?: (value: number) => string;
}[] = [
	{ id: '#', label: '#', minWidth: 50 },
	{
		id: 'email',
		label: 'Email Jobseeker',
		minWidth: 200,
		align: 'left',
	},
	{
		id: 'limit',
		label: 'Limit',
		minWidth: 200,
		align: 'left',
	},
	{
		id: 'current',
		label: 'Current',
		minWidth: 200,
		align: 'left',
	},
	{
		id: 'edit',
		label: 'Edit',
		minWidth: 50,
		align: 'center',
	},
];

export const ListLimitDataEmployer: {
	id: 'limitJob' | 'limitBuyCV' | 'limitTradeCv' | 'edit';

	label: string;
	minWidth?: number;
	align?: 'left' | 'right' | 'center';
	format?: (value: number) => string;
}[] = [
	{
		id: 'limitJob',
		label: 'Limit Job',
		minWidth: 200,
		align: 'left',
	},
	{
		id: 'limitBuyCV',
		label: 'Limit BuyCV',
		minWidth: 200,
		align: 'left',
	},
	{
		id: 'limitTradeCv',
		label: 'Limit Trade CV',
		minWidth: 200,
		align: 'left',
	},
	{
		id: 'edit',
		label: 'Edit',
		minWidth: 50,
		align: 'center',
	},
];
export const ListLimitDataReferEmployer: {
	id: 'ReferralAudits' | 'ReferralAuditsPerMonth' | 'edit';
	label: string;
	minWidth?: number;
	align?: 'left' | 'right' | 'center';
	format?: (value: number) => string;
}[] = [
	{
		id: 'ReferralAudits',
		label: 'Referral Audits',
		minWidth: 200,
		align: 'left',
	},
	{
		id: 'ReferralAuditsPerMonth',
		label: 'Referral Audits PerMonth',
		minWidth: 200,
		align: 'left',
	},
	{
		id: 'edit',
		label: 'Edit',
		minWidth: 50,
		align: 'center',
	},
];
export const ListBadKeyword: {
	id: 'No' | 'Keyword' | 'action';
	label: string;
	minWidth?: number;
	align?: 'left' | 'right' | 'center';
	format?: (value: number) => string;
	icon?: any;
}[] = [
	{
		id: 'No',
		label: 'No',
		minWidth: 200,
		align: 'left',
	},
	{
		id: 'Keyword',
		label: 'Keyword',
		minWidth: 200,
		align: 'left',
		icon: <SearchRoundedIcon fontSize='small' sx={{ fontWeight: 300, cursor: 'pointer' }} />,
	},
	{
		id: 'action',
		label: 'Action',
		minWidth: 50,
		align: 'center',
	},
];
export const ListReferAuditsEmployer: {
	id: 'no' | 'name' | 'referrerEmail' | 'refereeEmail' | 'refereePoint' | 'referrerPoint' | 'used-code';
	label: string;
	minWidth?: number;
	align?: 'left' | 'right' | 'center';
	format?: (value: number) => string;
	icon?: any;
}[] = [
	{
		id: 'no',
		label: 'No.',
		minWidth: 50,
		align: 'center',
	},
	{
		id: 'name',
		label: 'Employer name',
		minWidth: 100,
		align: 'center',
		icon: <SearchRoundedIcon fontSize='small' sx={{ fontWeight: 300, cursor: 'pointer' }} />,
	},
	{
		id: 'referrerEmail',
		label: 'Referrer email',
		minWidth: 100,
		align: 'center',
		icon: <SearchRoundedIcon fontSize='small' sx={{ fontWeight: 300, cursor: 'pointer' }} />,
	},
	{
		id: 'refereeEmail',
		label: 'Referee email',
		minWidth: 100,
		align: 'center',
		icon: <SearchRoundedIcon fontSize='small' sx={{ fontWeight: 300, cursor: 'pointer' }} />,
	},
	{
		id: 'refereePoint',
		label: 'Referee receive point',
		minWidth: 100,
		align: 'center',
	},
	{
		id: 'referrerPoint',
		label: 'referrer receive point',
		minWidth: 100,
		align: 'center',
	},
	{
		id: 'used-code',
		label: 'Used code',
		minWidth: 100,
		align: 'center',
	},
];
export const ListLimitDataJobseeker: {
	id: '#' | 'applyOneCompanyTimes' | 'applyAllCompanyTimes' | 'edit';
	label: string;
	minWidth?: number;
	align?: 'left' | 'right' | 'center';
	format?: (value: number) => string;
}[] = [
	{
		id: 'applyOneCompanyTimes',
		label: 'Apply one company times',
		minWidth: 200,
		align: 'left',
	},
	{
		id: 'applyAllCompanyTimes',
		label: 'Apply all company times',
		minWidth: 200,
		align: 'left',
	},
	{
		id: 'edit',
		label: 'Edit',
		minWidth: 50,
		align: 'center',
	},
];

export const ListJob: {
	id: '#' | 'title' | 'posterName' | 'PosterEmail' | 'createdAt' | 'expiredAt' | 'pic' | 'status';
	label: string;
	minWidth?: number;
	align?: 'left' | 'right' | 'center';
	format?: (value: number) => string;
	icon: any;
	picNameFilter?: boolean;
}[] = [
	{ id: '#', label: '#', minWidth: 50, icon: '' },
	{
		id: 'title',
		label: 'Title',
		minWidth: 200,
		align: 'left',
		icon: <SearchRoundedIcon fontSize='small' sx={{ fontWeight: 300, cursor: 'pointer' }} />,
	},
	{
		id: 'posterName',
		label: 'Poster Name',
		minWidth: 170,
		align: 'center',
		icon: '',
	},
	{
		id: 'PosterEmail',
		label: 'Poster Email',
		minWidth: 170,
		align: 'left',
		icon: <SearchRoundedIcon fontSize='small' sx={{ fontWeight: 300, cursor: 'pointer' }} />,
	},
	{
		id: 'createdAt',
		label: 'Create Date',
		minWidth: 170,
		align: 'left',
		icon: '',
	},
	{
		id: 'expiredAt',
		label: 'Expired Date',
		minWidth: 170,
		align: 'left',
		icon: '',
	},
	{
		id: 'pic',
		label: 'PIC',
		minWidth: 170,
		align: 'left',
		icon: <SearchRoundedIcon fontSize='small' sx={{ fontWeight: 300, cursor: 'pointer' }} />,
	},
	{ id: 'status', label: 'Status', minWidth: 50, icon: '', align: 'center' },
];

export const ListFAQ: {
	id: '#' | 'title' | 'content' | 'system' | 'category' | 'status';
	label: string;
	minWidth?: number;
	align?: 'left' | 'right' | 'center';
	format?: (value: number) => string;
	icon: any;
}[] = [
	{ id: '#', label: '#', minWidth: 50, icon: '' },
	{
		id: 'title',
		label: 'Title',
		minWidth: 200,
		align: 'left',
		icon: <SearchRoundedIcon fontSize='small' sx={{ fontWeight: 300, cursor: 'pointer' }} />,
	},
	{
		id: 'content',
		label: 'Content',
		minWidth: 200,
		align: 'left',
		icon: '',
	},
	{
		id: 'system',
		label: 'System',
		minWidth: 170,
		align: 'left',
		icon: '',
	},
	{
		id: 'category',
		label: 'Category',
		minWidth: 170,
		align: 'left',
		icon: '',
	},
	{ id: 'status', label: 'Status', minWidth: 50, icon: '' },
];

export const ListCategory: {
	id: '#' | 'name' | 'systems' | 'status';
	label: string;
	minWidth?: number;
	align?: 'left' | 'right' | 'center';
	format?: (value: number) => string;
	icon: any;
}[] = [
	{ id: '#', label: '#', minWidth: 50, icon: '' },
	{
		id: 'name',
		label: 'Category Name',
		minWidth: 100,
		align: 'left',
		icon: '',
	},
	{
		id: 'systems',
		label: 'Systems',
		minWidth: 170,
		align: 'left',
		icon: '',
	},
	{
		id: 'status',
		label: 'Status',
		minWidth: 100,
		align: 'left',
		icon: '',
	},
];

export const ListFAQLog: {
	id: '#' | 'username' | 'content' | 'created' | 'action';
	label: string;
	minWidth?: number;
	align?: 'left' | 'right' | 'center';
	format?: (value: number) => string;
	icon: any;
}[] = [
	{ id: '#', label: '#', minWidth: 50, icon: '' },
	{
		id: 'username',
		label: 'User',
		minWidth: 50,
		align: 'left',
		icon: '',
	},
	{
		id: 'content',
		label: 'Content',
		minWidth: 200,
		align: 'left',
		icon: '',
	},
	{
		id: 'created',
		label: 'Created',
		minWidth: 50,
		align: 'left',
		icon: '',
	},
	{
		id: 'action',
		label: 'action',
		minWidth: 50,
		align: 'center',
		icon: '',
	},
];

export const ListCvApplied: {
	id: '#' | 'fullname' | 'email' | 'pic' | 'cvApplied' | 'status' | 'type_submit' | 'createAt';
	label: string;
	minWidth?: number;
	align?: 'left' | 'right' | 'center';
	format?: (value: number) => string;
	icon: any;
}[] = [
	{ id: '#', label: '#', minWidth: 50, icon: '' },
	{
		id: 'fullname',
		label: 'Full Name',
		minWidth: 170,
		align: 'left',
		icon: '',
	},
	{
		id: 'email',
		label: 'Email',
		minWidth: 170,
		align: 'left',
		icon: '',
	},
	{
		id: 'pic',
		label: 'PIC',
		minWidth: 100,
		align: 'left',
		icon: <SearchRoundedIcon fontSize='small' sx={{ fontWeight: 300, cursor: 'pointer' }} />,
	},
	{
		id: 'cvApplied',
		label: 'Cv Applied',
		minWidth: 170,
		align: 'left',
		icon: '',
	},
	{
		id: 'type_submit',
		label: 'Type Submit',
		minWidth: 50,
		align: 'center',
		icon: '',
	},
	{
		id: 'createAt',
		label: 'Create At',
		minWidth: 50,
		align: 'center',
		icon: '',
	},
];
export const ScoreDiaryPaging: {
	id: '#' | 'firstJoinedAt' | 'email' | 'point' | 'timeBuy' | 'status' | 'registerAt' | 'surveyDate';
	label: string;
	minWidth?: number;
	align?: 'left' | 'right' | 'center';
	format?: (value: number) => string;
	icon: any;
	checkIcon: boolean;
}[] = [
	{ id: '#', label: '#', minWidth: 50, icon: '', checkIcon: false },
	{
		id: 'firstJoinedAt',
		label: 'First Joined At',
		minWidth: 100,
		align: 'left',
		icon: '',
		checkIcon: true,
	},
	{
		id: 'email',
		label: 'Email Login',
		minWidth: 200,
		align: 'left',
		icon: <SearchRoundedIcon fontSize='small' sx={{ fontWeight: 300, cursor: 'pointer' }} />,
		checkIcon: true,
	},
	{
		id: 'point',
		label: 'Point',
		minWidth: 170,
		align: 'left',
		icon: '',
		checkIcon: false,
	},
	{
		id: 'timeBuy',
		label: 'Date and time get point',
		minWidth: 100,
		align: 'left',
		icon: '',
		checkIcon: false,
	},
	{
		id: 'registerAt',
		label: 'Register At',
		minWidth: 100,
		align: 'left',
		icon: '',
		checkIcon: false,
	},
	{
		id: 'surveyDate',
		label: 'Survey Date',
		minWidth: 100,
		align: 'left',
		icon: '',
		checkIcon: false,
	},
	{
		id: 'status',
		label: 'Status',
		minWidth: 100,
		align: 'left',
		icon: '',
		checkIcon: false,
	},
];
export const EducationTermPaging: {
	id: '#' | 'schoolname' | 'IsVerify';
	label: string;
	minWidth?: number;
	align?: 'left' | 'right' | 'center';
	format?: (value: number) => string;
	icon: any;
	checkIcon: boolean;
}[] = [
	{ id: '#', label: '#', minWidth: 50, icon: '', checkIcon: false },
	{
		id: 'schoolname',
		label: 'School Name',
		minWidth: 200,
		align: 'left',
		icon: <SearchRoundedIcon fontSize='small' sx={{ fontWeight: 300, cursor: 'pointer' }} />,
		checkIcon: true,
	},
	{
		id: 'IsVerify',
		label: 'Is Verify',
		minWidth: 170,
		align: 'left',
		icon: '',
		checkIcon: false,
	},
];

export const InputCvPaging: {
	id: '#' | 'StaffEmail' | 'FullName' | 'Email' | 'Phone' | 'CreateAt';
	label: string;
	minWidth?: number;
	align?: 'left' | 'right' | 'center';
	format?: (value: number) => string;
	icon: any;
	checkIcon: boolean;
}[] = [
	{ id: '#', label: '#', minWidth: 50, icon: '', checkIcon: false },
	{
		id: 'StaffEmail',
		label: 'Staff Email',
		minWidth: 200,
		align: 'left',
		icon: '',
		checkIcon: false,
	},
	{
		id: 'FullName',
		label: 'Full Name',
		minWidth: 170,
		align: 'left',
		icon: '',
		checkIcon: false,
	},
	{
		id: 'Email',
		label: 'Email',
		minWidth: 170,
		align: 'left',
		icon: <SearchRoundedIcon fontSize='small' sx={{ fontWeight: 300, cursor: 'pointer' }} />,
		checkIcon: true,
	},
	{
		id: 'Phone',
		label: 'Phone',
		minWidth: 170,
		align: 'left',
		icon: '',
		checkIcon: false,
	},
	{
		id: 'CreateAt',
		label: 'Create At',
		minWidth: 170,
		align: 'left',
		icon: '',
		checkIcon: false,
	},
];
export const EmailTemplateHead: {
	id: '#' | 'model' | 'index_of_model' | 'content_vi' | 'content_en';
	label: string;
	maxWidth?: number;
	align?: 'left' | 'right' | 'center';
	format?: (value: number) => string;
	icon: any;
}[] = [
	{ id: '#', label: '#', maxWidth: 50, icon: '' },
	{
		id: 'model',
		label: 'Model',
		maxWidth: 50,
		align: 'left',
		icon: '',
	},
	{ id: 'index_of_model', label: 'Index of model', maxWidth: 100, icon: '' },
	{
		id: 'content_vi',
		label: 'Content Vi',
		maxWidth: 300,
		align: 'left',
		icon: '',
	},
	{
		id: 'content_en',
		label: 'Content En',
		maxWidth: 300,
		align: 'left',
		icon: '',
	},
];
export const ExtendTimeJobHead: {
	id: '#' | 'title' | 'date_mail_sent' | 'from_date' | 'to_date' | 'status';
	label: string;
	maxWidth?: number;
	align?: 'left' | 'right' | 'center';
	format?: (value: number) => string;
	icon: any;
}[] = [
	{ id: '#', label: '#', maxWidth: 50, icon: '' },
	{
		id: 'title',
		label: 'Title',
		maxWidth: 50,
		align: 'left',
		icon: '',
	},
	{ id: 'date_mail_sent', label: 'Date the email was sent ', maxWidth: 100, icon: '' },
	{
		id: 'from_date',
		label: 'From date',
		maxWidth: 300,
		align: 'left',
		icon: '',
	},
	{
		id: 'to_date',
		label: 'To date',
		maxWidth: 300,
		align: 'left',
		icon: '',
	},
	{
		id: 'status',
		label: 'Status',
		maxWidth: 300,
		align: 'left',
		icon: '',
	},
];
export const QAndAJobHead: {
	id: '#' | 'titleJob' ;
	label: string;
	maxWidth?: number;
	align?: 'left' | 'right' | 'center';
	format?: (value: number) => string;
	icon: any;
}[] = [
	{ id: '#', label: '#', maxWidth: 50, icon: '' },
	{
		id: 'titleJob',
		label: 'Title Job',
		maxWidth: 300,
		align: 'left',
		icon: '',
	},
];
