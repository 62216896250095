import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';

import styles from './Textfield.module.scss';
const useStyles = makeStyles({
	root: {
		'& label.Mui-focused': {
			color: '#054da7',
		},
		'& .MuiInput-underline:after': {
			borderBottomColor: '#054da7',
		},
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: '#eee',
			},
			'&:hover fieldset': {
				borderColor: '#eee',
			},
			'&.Mui-focused fieldset': {
				borderColor: '#054da7',
			},
		},
	},
});

function TextFieldMuiComp(props: any) {
	const classes = useStyles();
	const {
		name,
		label,
		onChange,
		placeholder,
		type,
		defaultValue,
		Adornment,
		disabled,
		value,
		key,
	} = props;

	return (
		<>
			<TextField
				className={classes.root}
				name={name}
				label={label}
				variant='outlined'
				InputLabelProps={{ shrink: false }}
				onChange={onChange}
				InputProps={{
					className: styles.input,
					inputProps: { min: 0 },
					endAdornment: (
						<InputAdornment position='end'>{Adornment}</InputAdornment>
					),
				}}
				placeholder={String(placeholder)}
				type={type}
				defaultValue={defaultValue}
				value={value}
				fullWidth
				disabled={disabled}
				key={key}
			/>
		</>
	);
}
export default TextFieldMuiComp;
