import styles from './Card.module.scss';

interface CardProps {
	title: string;
	content: any;
	icon: any;
	background: any;
}

export default function CardFeature(props: CardProps) {
	return (
		<div className={styles.card}>
			<div
				className={props.icon !== undefined ? styles.card__icon : undefined}
				style={props.background}
			>
				{props.icon}
			</div>
			<h1 className={styles.card__title}>{props.title}</h1>
			<div className={styles.card__content}>{props.content}</div>
		</div>
	);
}
