import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { ListMenu } from '../../../constant/sidebar';

interface InitialStateI {
	[key: string]: boolean;
}
const initialStatesHeader: InitialStateI = Object.fromEntries(ListMenu.map((i: any) => [i.name, false]));

const initialState = {
	openList: initialStatesHeader,
};

const headerSlices = createSlice({
	name: 'header',
	initialState,
	reducers: {
		setOpenList(state, action) {
			state.openList = action.payload({});
		},
	},
});

//Actions
export const headerActions = headerSlices.actions;

//Selectors
export const selectorSetOpenList = (state: RootState) => state.header.openList;
//reducer
export default headerSlices.reducer;
