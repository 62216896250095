import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import { FormikProvider } from 'formik';
import * as React from 'react';

export default function MenuButtonComponent({
	button,
	title,
	menuItem,
	cancelClick,
	classNameIconCancel,
	methods,
}: {
	button: any;
	title: string;
	menuItem: any;
	cancelClick?: boolean;
	classNameIconCancel?: any;
	methods?: any;
}) {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	return (
		<>
			<Box>
				<Tooltip title={title ? title : null}>
					<div onClick={handleClick}>{button ? button : null}</div>
				</Tooltip>
			</Box>
			<Menu
				anchorEl={anchorEl}
				id='menu'
				open={open}
				onClose={handleClose}
				PaperProps={{
					elevation: 0,
					sx: {
						overflow: 'visible',
						filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
						borderRadius: '10px',
						mt: 1.5,
						left: '780px',
						width: '260px',
						'&:before': {
							content: '""',
							display: 'block',
							position: 'absolute',
							top: 0,
							right: 22,
							width: 10,
							height: 10,
							bgcolor: 'background.paper',
							transform: 'translateY(-50%) rotate(45deg)',
							zIndex: 0,
						},
					},
				}}
				transformOrigin={{ horizontal: 'right', vertical: 'top' }}
				anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
			>
				{methods ? <FormikProvider value={methods}>{menuItem}</FormikProvider> : menuItem}
				{cancelClick ? (
					<div className={classNameIconCancel}>
						<IconButton
							onClick={() => {
								setAnchorEl(null);
							}}
						>
							<CloseRoundedIcon sx={{ color: 'red' }} />
						</IconButton>
					</div>
				) : null}
			</Menu>
		</>
	);
}
