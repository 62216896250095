import styles from './Button.module.scss';

interface initState {
	title: string;
	type: 'submit' | 'reset' | 'button' | undefined;
	style: any;
	onClick: any;
	disabled?: boolean;
	icon?: any;
}

function ButtonWhite(props: initState) {
	return (
		<button
			className={styles.buttonWhite}
			type={props.type}
			style={props.style}
			onClick={props.onClick}
			disabled={props.disabled}
		>
			<span>{props.icon}</span>
			{props.title}
		</button>
	);
}
export default ButtonWhite;
