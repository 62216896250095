/* eslint-disable @typescript-eslint/no-unused-vars */
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../../commons/Header';
import { InputSideBar } from '../../commons/SideBar/InputSideBar';
import { RoutesRedirect } from '../../constant/routes';
import styles from './LayoutPrivate.module.scss';
function MainLayoutPrivate() {
	const [token, setTolen] = useState<any>();
	useEffect(() => {
		const getToken = Cookies.get('access_token');
		if (getToken !== undefined) {
			setTolen(getToken);
		} else {
			Cookies.remove('role');
			Cookies.remove('access_token');
			localStorage.removeItem('userInfor');
			window.location.pathname = RoutesRedirect.loginPage;
		}
	}, []);
	return (
		<>
			{token !== undefined && (
				<div id='page-wrap' className={styles.main__layout}>
					<Header />
					<div className={styles.main__layout__wrapper}>
						<InputSideBar />
						<Outlet />
					</div>
				</div>
			)}
		</>
	);
}
export default MainLayoutPrivate;
