import { TextField, TextFieldProps } from '@mui/material';
import ListSubheader from '@mui/material/ListSubheader';
import MenuItem from '@mui/material/MenuItem';
import { FormikProvider } from 'formik';
import styles from './Filter.module.scss';
import { useEffect } from 'react';

interface FilterInit {
	onChange: any;
	listSub?: string;
	menuData: any;
	value?: any;
	additionHtml?: any;
	methods?: any;
	name: string;
	formProps: TextFieldProps;
}

function FilterComponent(props: FilterInit) {
	return (
		<>
			<div style={{ marginTop: '10px', width: '100%' }}>
				<TextField
					{...props.formProps}
					select
					defaultValue=''
					name={props.name}
					size='small'
					onChange={props.onChange}
					sx={{ borderRadius: '10px', marginBottom: '10px' }}
					value={props.value}
				>
					<MenuItem value={''} className={styles.filter__categoryNone}>
						<em>None</em>
					</MenuItem>
					<ListSubheader sx={{ textAlign: 'center', fontWeight: 500 }}>{props.listSub}</ListSubheader>
					{props.menuData?.map((e: any, idx: any) => (
						<MenuItem value={e.value} className={styles.filter__category} key={idx}>
							{e.label}
						</MenuItem>
					))}
				</TextField>
				{props.additionHtml && <FormikProvider value={props.methods}>{props.additionHtml}</FormikProvider>}
			</div>
		</>
	);
}

export default FilterComponent;
