import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';

export interface authState {
	userInfor: any;
	setLoading: boolean;
	logedIn: boolean;
}

const initialState: authState = {
	userInfor: [],
	setLoading: false,
	logedIn: false,
};

const authenSlice = createSlice({
	name: 'authenticate',
	initialState,
	reducers: {
		setUserInfor(state, action) {
			state.userInfor = action.payload;
		},

		setLoading(state, action) {
			state.setLoading = action.payload;
		},

		setLogedIn(state, action) {
			state.logedIn = action.payload;
		},
	},
});

//Actions
export const authenActions = authenSlice.actions;

//Selectors
export const selectorSetUserInfor = (state: RootState) =>
	state.authenticate.userInfor;
export const selectorSetLoading = (state: RootState) =>
	state.authenticate.setLoading;
export const selectorSetLogedIn = (state: RootState) =>
	state.authenticate.logedIn;

//reducer
export default authenSlice.reducer;
