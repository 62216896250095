import { instance } from './axiosClient';
export interface IExtendTimeJob {
	title: string;
	startedAt: string;
	fromDate: string;
	toDate: string;
	status: number;
}
export class QAndAFromCandidateAndHrConfigApi {
	public static v1GetQuestionsAndAnswers(currentPage?: string, numberRow?: number) {
		const pageParam = currentPage ? currentPage + 1 : 1;
		const pageRows = numberRow ? numberRow : 10;
		return instance.get(`/job-comment/admin-site?lang=en&page=${pageParam}&limit=${pageRows}`);
	}
	public static v1GetNotification() {
		return instance.get(`/notification/me`);
	}
	public static v1GetNotificationUnread() {
		return instance.get(`/notification/me/unread`);
	}
	public static v1GetQuestionsAndAnswersJob(uuid?: string) {
		return instance.get(`/job-comment/admin-site/job/${uuid}`);
	}
	public static v1GetQuestionsAndAnswersReply(uuid?: string) {
		return instance.get(`/job-comment/admin-site/reply/${uuid}`);
	}
	public static v1EditQuestion(requestBody?: any, uuid?: string) {
		return instance.put(`/job-comment/${uuid}?lang=en`, requestBody);
	}
	public static v1EditAnswer(requestBody?: any, uuid?: string) {
		return instance.put(`/job-comment/reply/${uuid}?lang=en`, requestBody);
	}
	public static v1PutClickNotification(uuid?: string) {
		return instance.put(`/notification/${uuid}/click`);
	}
}
