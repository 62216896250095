import { Airplay } from '@mui/icons-material';
import ApprovalIcon from '@mui/icons-material/Approval';
import CoPresentIcon from '@mui/icons-material/CoPresent';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import ContentPasteSearchRoundedIcon from '@mui/icons-material/ContentPasteSearchRounded';
import CurrencyExchangeRoundedIcon from '@mui/icons-material/CurrencyExchangeRounded';
import DvrIcon from '@mui/icons-material/Dvr';
import EmojiTransportationRoundedIcon from '@mui/icons-material/EmojiTransportationRounded';
import MonitorRoundedIcon from '@mui/icons-material/MonitorRounded';
import PersonOutlineRoundedIcon from '@mui/icons-material/PersonOutlineRounded';
import QuizIcon from '@mui/icons-material/Quiz';
import EmailIcon from '@mui/icons-material/Email';

export const ListMenu: {
	id: number;
	name: string;
	icon?: any;
	path?: string;
	route?: string;
	role?: string[];
	items?: any;
}[] = [
		{
			id: 1,
			name: 'Home',
			icon: <MonitorRoundedIcon fontSize='small' />,
			path: '/admin/home',
			route: '/admin/home',
			role: ['U_ADMIN'],
		},
		{
			id: 2,
			name: 'User',
			icon: <PersonOutlineRoundedIcon fontSize='small' />,
			path: '/admin/user',
			route: '/admin/user',
			role: ['U_ADMIN', 'U_ADMIN_OF_SALE'],
		},
		{
			id: 3,
			name: 'Company',
			icon: <EmojiTransportationRoundedIcon fontSize='small' />,
			path: '/admin/company',
			route: '/admin/company',
			role: ['U_ADMIN', 'U_ADMIN_OF_SALE'],
		},
		{
			id: 4,
			name: 'Jobs',
			icon: <ContentPasteSearchRoundedIcon fontSize='small' />,
			role: ['U_ADMIN', 'U_ADMIN_OF_SALE'],
			items: [
				{
					id: 1,
					name: 'Recruit',
					icon: <ContentPasteSearchRoundedIcon fontSize='small' />,
					path: '/admin/recruit',
					route: '/admin/recruit',
					role: ['U_ADMIN', 'U_ADMIN_OF_SALE'],
				},
				{
					id: 2,
					name: 'Comment Q&A Job',
					icon: <CoPresentIcon fontSize='small' />,
					path: '/admin/comment-questions-and-answers-job',
					route: '/admin/comment-questions-and-answers-job',
					role: ['U_ADMIN', 'U_ADMIN_OF_SALE'],
				},
			]
		},
		{
			id: 5,
			name: 'CV Management',
			role: ['U_ADMIN', 'U_ADMIN_OF_SALE'],
			icon: <Airplay fontSize='small' />,
			items: [
				{
					id: 1,
					name: 'Confirm CV',
					icon: <CoPresentIcon fontSize='small' />,
					path: '/admin/confirm-cv',
					route: '/admin/confirm-cv',
					role: ['U_ADMIN', 'U_ADMIN_OF_SALE'],
				},
				{
					id: 2,
					name: 'Upload CV',
					icon: <CoPresentIcon fontSize='small' />,
					path: '/admin/confirm-cv/upload',
					route: '/admin/confirm-cv/upload',
					role: ['U_ADMIN'],
				},
				{
					id: 3,
					name: 'Input CV',
					icon: <CoPresentIcon fontSize='small' />,
					path: '/admin/input-cv',
					route: '/admin/input-cv',
					role: ['U_ADMIN'],
				},
				{
					id: 4,
					name: 'Input Employer CV',
					icon: <CoPresentIcon fontSize='small' />,
					path: '/admin/employer-input-cv',
					route: '/admin/employer-input-cv',
					role: ['U_ADMIN', 'U_ADMIN_OF_SALE'],
				},
				{
					id: 5,
					name: 'Input Employer File CV',
					icon: <CoPresentIcon fontSize='small' />,
					path: '/admin/employer-input-cv-file',
					route: '/admin/employer-input-cv-file',
					role: ['U_ADMIN', 'U_ADMIN_OF_SALE'],
				},
				{
					id: 6,
					name: 'Cv Applied',
					icon: <ApprovalIcon fontSize='small' />,
					path: '/admin/cv-applied',
					route: '/admin/cv-applied',
					role: ['U_ADMIN', 'U_ADMIN_OF_SALE'],
				},
				{
					id: 7,
					name: 'Optimize CV',
					icon: <DvrIcon fontSize='small' />,
					path: '/admin/optimize-cv',
					route: '/admin/optimize-cv',
					role: ['U_ADMIN', 'U_ADMIN_OF_SALE'],
				},
			],
		},
		{
			id: 6,
			name: 'Blog Management',
			role: ['U_ADMIN'],
			icon: <Airplay fontSize='small' />,
			items: [
				{
					id: 1,
					name: 'Blog Category',
					icon: <ContentPasteSearchRoundedIcon fontSize='small' />,
					path: '/admin/blog-category',
					route: '/admin/blog-category',
					role: ['U_ADMIN'],
				},
				{
					id: 2,
					name: 'Blog',
					icon: <ContentPasteSearchRoundedIcon fontSize='small' />,
					path: '/admin/blog',
					route: '/admin/blog',
					role: ['U_ADMIN'],
				},
			],
		},
		{
			id: 7,
			name: 'Management Term',
			role: ['U_ADMIN'],
			icon: <Airplay fontSize='small' />,
			items: [
				{
					id: 1,
					name: 'Skill Term',
					icon: <ContentPasteSearchRoundedIcon fontSize='small' />,
					path: '/admin/skill-term',
					route: '/admin/skill-term',
					role: ['U_ADMIN'],
				},
				{
					id: 1,
					name: 'Education Term',
					icon: <ContentPasteSearchRoundedIcon fontSize='small' />,
					path: '/admin/education-term',
					route: '/admin/education-term',
					role: ['U_ADMIN'],
				},
				{
					id: 3,
					name: 'Limit Posting & Trading & purchases',
					icon: <ContentPasteSearchRoundedIcon fontSize='small' />,
					path: '/admin/limit-data',
					route: '/admin/limit-data',
					role: ['U_ADMIN'],
				},
			],
		},
		{
			id: 8,
			name: 'Feature Admin',
			icon: <Airplay fontSize='small' />,
			role: ['U_ADMIN'],
			items: [
				{
					id: 1,
					name: 'Transaction',
					icon: <CurrencyExchangeRoundedIcon fontSize='small' />,
					path: '/admin/transaction',
					route: '/admin/transaction',
					role: ['U_ADMIN'],
				},
				{
					id: 2,
					name: 'Package',
					icon: <ConfirmationNumberOutlinedIcon fontSize='small' />,
					path: '/admin/package',
					route: '/admin/package',
					role: ['U_ADMIN'],
				},
				{
					id: 3,
					name: 'Release Management',
					icon: <DvrIcon fontSize='small' />,
					path: '/admin/release-management',
					route: '/admin/release-management',
					role: ['U_ADMIN'],
				},
				{
					id: 18,
					name: 'Employer Referer',
					icon: <DvrIcon fontSize='small' />,
					path: '/admin/referer',
					route: '/admin/referer',
					role: ['U_ADMIN', 'U_ADMIN_OF_SALE'],
				},
				{
					id: 4,
					name: 'Email Template',
					icon: <EmailIcon fontSize='small' />,
					path: '/admin/email-template',
					route: '/admin/email-template',
					role: ['U_ADMIN'],
				},
				{
					id: 4,
					name: 'Extend Time Job When Holiday',
					icon: <DvrIcon fontSize='small' />,
					path: '/admin/extend-time-job-holiday',
					route: '/admin/extend-time-job-holiday',
				},
				{
					id: 5,
					name: 'Bad Keyword',
					icon: <DvrIcon fontSize='small' />,
					path: '/admin/bad-keyword',
					route: '/admin/bad-keyword',
					role: ['U_ADMIN'],
				},
			],
		},
		{
			id: 9,
			name: 'Score diary',
			icon: <ContentPasteSearchRoundedIcon fontSize='small' />,
			path: '/admin/score-diary',
			route: '/admin/score-diary',
			role: ['U_ADMIN', 'U_ADMIN_OF_SALE', 'U_SALE', 'U_SALE_ADMIN'],
		},
		{
			id: 10,
			name: 'Logger',
			icon: <DvrIcon fontSize='small' />,
			path: '/admin/logger',
			route: '/admin/logger',
			role: ['U_ADMIN'],
		},
		{
			id: 11,
			name: 'FAQ',
			icon: <QuizIcon fontSize='small' />,
			path: '/admin/faq',
			route: '/admin/faq',
			role: ['U_ADMIN', 'U_ADMIN_OF_SALE'],
		},
	];
