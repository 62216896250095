import { ESurveyCandidateStatus } from '../../constant/common';
import { instance } from './axiosClient';

export const DEFAULT_PAGE = 1;
export const DEFAULT_LIMIT = 10;

export class LangInputDto {
	lang?: string;
}
export class PaginationInputDto extends LangInputDto {
	page?: number;
	limit?: number;
}
export class GetScoreDiaryPagingInputDto extends PaginationInputDto {
	email?: string;
	status?: ESurveyCandidateStatus;
}
export class ScoreDiaryApiCall {
	public static getListScoreDiaryPaging(currentPage?: number, numberRow?: number) {
		const pageParam = currentPage ? currentPage + 1 : 1;
		const pageRows = numberRow ? numberRow : 10;

		return instance.get(`/point/bonus/survey-emails?lang=en&page=${pageParam}&limit=${pageRows}`);
	}

	public static getScoreDiaryPaging(query: GetScoreDiaryPagingInputDto) {
		return instance.get(`/point/bonus/survey-emails`, {
			params: { ...query, page: query?.page || DEFAULT_PAGE, limit: query?.limit || DEFAULT_LIMIT },
		});
	}
}
