import { Button, TableCell, TableRow } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import _get from 'lodash/get';
import * as React from 'react';
import { toast } from 'react-toastify';
import CardFeature from '../../../components/Card/CardFeature';
import MenuButtonComponent from '../../../components/Menu/menuButton';
import TableComponents from '../../../components/Table';
import TextFieldMuiComp from '../../../components/Textfield/textfieldMui';
import { EducationTermPaging } from '../../../constant/column/common';
import { useDebounce } from '../../../hooks/useDebounce';
import { educationTermApiCall } from '../../../services/Admin/educationTerm';
import styles from './EducationTerm.module.scss';

interface EducationTermManagementProps {}

const EducationTermManagement: React.FC<EducationTermManagementProps> = () => {
	const [limit, setLimit] = React.useState(10);
	const [pageIndex, setPageIndex] = React.useState(0);
	const ref = React.useRef(null);
	const [filter, setFilter] = React.useState('');
	const [trigger, setTrigger] = React.useState(-1);

	const debouncedSearchFilter = useDebounce(filter, 500);

	const educationTerm = useQuery(['educationTerm', limit, pageIndex, debouncedSearchFilter], async () => {
		const res = await educationTermApiCall.v1GetPagingApi(pageIndex, limit, debouncedSearchFilter);

		return res;
	});

	const acceptMutation = useMutation(
		async (id: string) => {
			const res = await educationTermApiCall.accept(id);

			return res;
		},
		{
			onSuccess: () => {
				toast.success('Accept success');
				educationTerm.refetch();
			},
			onError: () => {
				toast.error('Accept fail');
			},
		}
	);

	const rejectMutation = useMutation(
		async (id: string) => {
			const res = await educationTermApiCall.reject(id);

			return res;
		},
		{
			onSuccess: () => {
				toast.success('Reject success');
				educationTerm.refetch();
			},
			onError: () => {
				toast.error('Reject fail');
			},
		}
	);
	React.useEffect(() => {
		document.title = 'Admin - Education Term';
	}, []);

	return (
		<>
			<div
				className='p-6'
				style={{
					backgroundColor: ' rgb(244 243 243)',
				}}
			>
				<div className='layout h-screen'>
					<div className=''>
						<div className='toolbar pb-4'>
							<div className='flex items-center justify-between toolbar_container'>
								<div className='title'>
									<h1 className='header text-lg'>Education Term Management</h1>
								</div>
							</div>
						</div>
						<div className=''>
							<CardFeature
								title={''}
								content={
									<TableComponents
										title=''
										icon={undefined}
										specialHeader={<>Detail</>}
										tableHead={EducationTermPaging.map((item, index) => (
											<TableCell
												key={index}
												align={item.align}
												style={{
													background: '#f5f8fa',
													color: '#a1a5b7',
													fontWeight: 600,
													fontFamily: 'Inter, Helvetica, sans-serif',
													borderRight: '1px solid #fff',
													borderBottom: '0px solid #eee',
												}}
												className={styles.user__content__container__head}
												ref={ref}
											>
												<div className={styles.user__content__container__head__box}>
													{item.label}
													<MenuButtonComponent
														title={'Search'}
														button={
															<div
																className={styles.user__icon}
																onClick={() => {
																	if (trigger === index) {
																		setTrigger(-1);
																		return;
																	}

																	setTrigger(index);
																}}
															>
																{item.icon}
															</div>
														}
														menuItem={
															<div className={styles.user__search}>
																<div className={styles.user__toolbar__container__group__box__menu}>
																	<TextFieldMuiComp
																		name={'search-email'}
																		onChange={(e: any) => {
																			setFilter(e.target.value);
																		}}
																		type='text'
																		placeholder='Search'
																	/>
																</div>
															</div>
														}
													/>
												</div>
											</TableCell>
										))}
										tableData={educationTerm.data?.data?.map((item, index: number) => {
											return (
												<>
													<TableRow hover role='checkbox' tabIndex={-1} key={index}>
														<TableCell className='' align={'left'}>
															{_get(item, '', index + 1)}
														</TableCell>
														<TableCell className='' align={'left'}>
															{_get(item, 'schoolname', '')}
														</TableCell>
														<TableCell className='' align={'left'}>
															{Boolean(_get(item, 'isVerify', 1)) ? (
																<div className='text-green-600'>Yes</div>
															) : (
																<div className='text-red-600'>No</div>
															)}
														</TableCell>
														<TableCell align={'center'}>
															{!Boolean(_get(item, 'isVerify', '')) ? (
																<Button
																	size='small'
																	variant='contained'
																	onClick={() => {
																		acceptMutation.mutate(_get(item, 'schoolid', ''));
																	}}
																	style={{
																		padding: '5px',
																		backgroundColor: '#009ef7',
																		color: '#fff',
																		borderRadius: '5px',
																	}}
																>
																	Accept
																</Button>
															) : (
																<Button
																	size='small'
																	variant='contained'
																	color='error'
																	onClick={() => {
																		rejectMutation.mutate(_get(item, 'schoolid', ''));
																	}}
																	style={{
																		padding: '5px',
																		backgroundColor: 'red',
																		color: '#fff',
																		borderRadius: '5px',
																	}}
																>
																	Reject
																</Button>
															)}
														</TableCell>
													</TableRow>
												</>
											);
										})}
										dataTable={educationTerm.data?.meta.totalItems || 0}
										page={pageIndex}
										rowsPerPage={limit}
										onPageChange={(_: any, newPage: number) => {
											setPageIndex(newPage);
										}}
										onRowsPerPageChange={({ target }: any) => {
											setLimit(target.value);
										}}
									/>
								}
								icon={undefined}
								background={undefined}
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
export default EducationTermManagement;
