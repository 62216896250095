/* eslint-disable @typescript-eslint/no-unused-vars */
import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../../commons/Header';
import SideBar from '../../commons/SideBar';
import { RoutesRedirect } from '../../constant/routes';
import styles from './LayoutPrivate.module.scss';

function InputLayoutPrivate() {
	const getToken = Cookies.get('access_token');
	useEffect(() => {
		if (getToken == undefined) {
			Cookies.remove('role');
			Cookies.remove('access_token');
			localStorage.removeItem('userInfor');
			window.location.pathname = RoutesRedirect.loginPage;
		}
	}, []);
	return (
		<>
			{getToken !== undefined && (
				<div id='page-wrap' className={styles.main__layout}>
					<Header />
					<div className={styles.main__layout__wrapper}>
						<SideBar />
						<Outlet />
					</div>
				</div>
			)}
		</>
	);
}
export default InputLayoutPrivate;
