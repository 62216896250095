import axios from 'axios';
// import queryString from "query-string";
import Cookies from 'js-cookie';

const instance = axios.create({
	baseURL: process.env.REACT_APP_PUBLIC_API,
	headers: {
		// "Content-Type": "application/json",
	},
	// paramsSerializer: {
	//   encode: (params) => queryString.stringify(params),
	// },
});

const BASE_PATH = process.env.REACT_APP_BASE_PATH;
instance.interceptors.request.use(async (config) => {
	const TOKEN = Cookies.get('access_token');
	// is development mode
	if (TOKEN) {
		(config.headers as any)['Authorization'] = `Bearer ${TOKEN}`;
	} else {
		Cookies.remove('role');
		Cookies.remove('access_token');
		localStorage.removeItem('userInfor');
		window.location.pathname = `${BASE_PATH}/admin/login`;
	}
	return config;
});

instance.interceptors.response.use(
	(response) => {
		if (response && response.data) {
			return response.data;
		}
		return response;
	},
	async (error) => {
		const REFRESH_TOKEN = Cookies.get('refresh_token');
		const originalConfig = error.config;
		if (error.response?.status === 401) {
			Cookies.remove('role');
			Cookies.remove('access_token');
			localStorage.removeItem('userInfor');
			setTimeout(() => {
				window.location.pathname = '/admin/login';
			}, 500);
			await fetch('${BASE_PATH}/auth/refresh-token', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					token: REFRESH_TOKEN,
				}),
			})
				.then((res) => res.json())
				.then((res) => {
					const { access_token } = res?.data;
					if (res?.status === 200 && access_token) {
						originalConfig.headers.Authorization = `Bearer ${access_token}`;
						return instance(originalConfig);
					}
				});
		}
		throw error;
	}
);

export { instance };
