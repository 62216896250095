import React from 'react';

export function useDebounce<T>(value: T, delay: number, cb?: () => void) {
	const [debouncedValue, setDebouncedValue] = React.useState<T>(value);

	React.useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedValue(value);
			if (cb) {
				cb();
			}
		}, delay);

		return () => {
			clearTimeout(handler);
		};
	}, [value, delay, cb]);

	return debouncedValue;
}
